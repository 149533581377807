<template>
  <base-header class="pt-md-6 base-header bg-white shadow">
    <base-nav
      container-classes="container-fluid"
      class="navbar-top navbar-expand"
      :class="{ 'navbar-dark': type === 'default' }"
    >
      <!-- <a href="#" aria-current="page"
        class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"> 
        {{ $route.name }} 
      </a> -->

      <!-- <span
        class="m-0 text-center"
        :class="{ 'text-dark': !isMobile }"
        show
        dismissible
        style="font-size: 18px"
        v-if="permission('email.campanha')"
      >
        <base-button outline type="info" @click="() => $router.push('/email/marketing')">
          <i class="ni ni-email-83"></i>
          <span>E-mails</span>
        </base-button>
      </span> -->

      <!-- <span
        class="m-0 text-center"
        :class="{ 'text-dark': !isMobile }"
        show
        dismissible
        style="font-size: 18px; cursor: pointer;"
      >
        <a :href="getLinkCatalago" target="_blank" class="btn shadow" type="default">
          <img src="@/assets/img/page/catalogo.png" width="25px">
          <span> Meu Catálogo</span>
        </a>
      </span>  -->
      <span
        class="m-0 text-center"
        :class="{ 'text-dark': !isMobile }"
        show
        dismissible
        style="font-size: 18px; cursor: pointer;"
      >
        <a :href="getLinkCatalagoVendedores" target="_blank" class="btn shadow" type="default">
          <img src="@/assets/img/page/catalogo.png" width="25px">
          <span> Catálogo de Vendedores</span>
        </a>
      </span>
      <span
        class="m-0 text-center"
        :class="{ 'text-dark': !isMobile }"
        show
        dismissible
        style="font-size: 18px"
        v-if="
          getUserLogged.company && getUserLogged.company.company_plan.status === 'TESTING'
        "
      >
        Bem vindo a Liga ERP! Seu período de avaliação termina em
        {{ daysCompanyToTest }} dias
        <!-- <base-button
          outline
          size="sm"
          type="info"
          class=""
          @click="() => $router.push('/marketplace')"
          >ver planos</base-button
        > -->
      </span>

      <span
        class="m-0 text-center"
        :class="{ 'text-dark': !isMobile }"
        show
        style="font-size: 18px"
        variant="warning"
        v-if="
          getUserLogged.company &&
          getUserLogged.company.company_plan.status === 'CANCELED'
        "
      >
        Pagamento não registrado. Sua assinatura encontra-se com o status de cancelamento.
        <base-button
          outline
          size="sm"
          type="warning"
          class=" "
          @click="() => $router.push('/marketplace')"
          >Regularizar</base-button
        >
      </span>
      <b-navbar-nav class="align-items-center ml-md-auto">
        <li class="nav-item d-sm-none">
          <a
            class="nav-link"
            href="#"
            data-action="search-show"
            data-target="#navbar-search-main"
          >
            <i class="ni ni-zoom-split-in"></i>
          </a>
        </li>
      </b-navbar-nav>

      <Notificaction />

      <b-navbar-nav class="align-items-center ml-auto ml-md-0">
        <base-dropdown
          menu-on-right
          class="nav-item"
          tag="li"
          title-tag="a"
          title-classes="nav-link pr-0"
        >
          <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
            <b-media no-body class="align-items-center">
              <!-- <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="/../../img/theme/team-4.jpg">
              </span> -->
              <b-media-body class="ml-2 d-none d-lg-block">
                <span
                  class="font-weight-600 description mb-0 text-sm font-weight-bold"
                  :class="{ 'text-dark': !isMobile }"
                  v-if="getUserLogged.id"
                >
                  {{ getUserLogged.name }}
                </span>
              </b-media-body>
            </b-media>
          </a>

          <!-- Item Sub menu -->
          <DropItemMenu />
        </base-dropdown>
      </b-navbar-nav>
    </base-nav>
  </base-header>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import { mapGetters } from "vuex";
import DropItemMenu from "@/views/Layout/DropItemMenu.vue";
import moment from "moment";
import Notificaction from "@/views/Layout/Notificaction.vue";

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal,
    DropItemMenu,
    Notificaction,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description: "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapGetters(["getUserLogged", "permission", "isMobile", 'getLinkCatalago', 'getLinkCatalagoVendedores']),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      daysCompanyToTest: 0,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.getUserLogged.company) {
        const testDateStart = moment();
        const testDateEnd = moment(this.getUserLogged.company.company_plan.test_date_end);
        this.daysCompanyToTest = Math.abs(testDateEnd.diff(testDateStart, "days"));
      }
    }, 1000)
    
  },
};
</script>

<style>
.base-header {
  /* background-color: #686a6c !important; */
}
</style>
