import Client from '@/services/Client'
const axios = new Client()

export const banner = {
  state: {
    banners: []
  },
  getters: {
    getBanners: state => state.banners,
  },
  mutations: { 
    setBanners: (state, value) => state.banners = value,
  },
  actions: {
    listBanners({commit}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'banners'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          commit('setBanners', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    updateBanner({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.postFormData('banners/update', data)
          .then(res => {
            if(res.data) {
              dispatch('listBanners')
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    removeBanner({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.delete('banners/'+data.id)
          .then(res => {
            if(res.status == 204) {
              dispatch('listBanners')
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    crateBanner({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.postFormData('banners', data)
          .then(res => {
            if(res.data) {
              dispatch('listBanners')
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
