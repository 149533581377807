import Client from '@/services/Client'
const axios = new Client()

export const report = {
  state: {
    amountWallet: [],
    saleChartSixMonth: [],
    saleChartByDate: [],
  },
  getters: {
    getAmountWallet: state => state.amountWallet,
    getSaleChartSixMonth: state => state.saleChartSixMonth,
    getSaleChartByDate: state => state.saleChartByDate,
  },
  mutations: {
    setAmountWallet: (state, value) => state.amountWallet = value,
    setSaleChartSixMonth: (state, value) => state.saleChartSixMonth = value,
    setSaleChartByDate: (state, value) => state.saleChartByDate = value,
  },
  actions: {
    amountsWallet({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-amount-movement'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit('setAmountWallet', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    saleChartSixMonth({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-sale-chart-six-month'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit('setSaleChartSixMonth', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    saleChartByDate({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-sale-chart-by-date'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit('setSaleChartByDate', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    topSellingProducts({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/top-selling-products'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    topSeller({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/top-seller'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    topProvider({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/top-provider'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    topCustomer({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/top-customer'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    totalProduct({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-product'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    totalSales({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-sales'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    totalAmountMovementType({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-amount-movement-type'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    totalCommission({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/total-commission'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    totalLucro({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/lucros'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    totalEntradas({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/entradas'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    getMetricsMapaBrasil({ commit }, url) {
      return new Promise((resolve, reject) => {
        let uri = 'report/map/brasil'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    getMetricsMapaBrasilUf({ commit }, uf) {
      return new Promise((resolve, reject) => {
        let uri = 'report/map/brasil/uf/'+uf
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    
  }
}
