import Client from '@/services/Client'
const axios = new Client()

export const category = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    listCategories({commit}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'categories'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          console.log('res.data', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    updateCategories({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('categories/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    crateCategories({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('categories', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
