import Client from '@/services/Client'
const axios = new Client()
import Cookies from 'js-cookie'

export const user = {
  state: {
    users: [],
    types:{
      'OFF':'Inativo',
      'ADMIN': 'Administrador',
      'SELLER': 'vendedor',
      'COLETOR': 'Coletor',
      'FUNCIONARIO': 'Funcionário'
    }
  },
  getters: {
    getUsers: state => state.users,
    geTypes: state => state.types,
  },
  mutations: {
    // Users
    setUsers: (state, value) => state.users = value,
  },
  actions: {
    // Users
    login({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.login('login', data)
          .then(response => {
           
            response = response.data
            
            if(response.access_token) {
              Cookies.set('access_token', response.access_token, { expires: 100, sameSite: 'None', secure: true })
              Cookies.set('isLogged', true)
              Cookies.set('roles', JSON.stringify(response.roles))
              commit('setUserLoggedRoles', response.roles)
              commit('setUserLogged', {...response.user, is_completed: response.is_completed})
              resolve(response)
            } else reject(response)
          })
          .catch(err => reject(err))
      })
    },
    me({commit}, data) {
      if(!Cookies.get('isLogged')){
        console.info('401')
        return false
      }
      return new Promise((resolve, reject) => {
        axios.get('user/logged', data)
          .then(response => {
            Cookies.set('roles', JSON.stringify(response.data.roles))
            commit('setUserLoggedRoles', response.data.roles)
            commit('setUserLogged',{...response.data.user, is_completed: response.data.is_completed})
            
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    findUser({commit}, data) {

      return new Promise((resolve, reject) => {
        axios.get('user/show/'+data.id)
          .then(response => {
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    updateUsers: ({commit}, data) => commit('setUsers', data),
    listUsers({commit}, data) {
      commit('setShowSkeletonTable', true)

      return new Promise((resolve, reject) => {

        let uri = 'user';
     
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }


        axios.get(uri)
        .then(res => {
          if(res.data) {
            commit('setUsers', res.data)
          } else {
            commit('setUsers', res.data.data)
            commit('setLinksUsers', res.data.links)
          }
          resolve(res)
        })
        .catch(err => reject(err))
        .finally(()=>{
          commit('setShowSkeletonTable', false)
        })
      })
    },
    listPublicUsers({commit}, data) {
      commit('setShowSkeletonTable', true)
      data.public_company  = true
      return new Promise((resolve, reject) => {

        let uri = 'user/list/public';
     
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
        .then(res => {
          if(res.data) {
            commit('setUsers', res.data)
          } 
          resolve(res)
        })
        .catch(err => reject(err))
        .finally(()=>{
          commit('setShowSkeletonTable', false)
        })
      })
    },
    createUser({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('user', data)
          .then(res => {
            if(res.data.success) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    getCatalagoVendedores({commit}, data){
      return new Promise((resolve, reject) => {
        axios.get('user/catalago/vendedor/'+data)
          .then(res => {
              resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    updateUser({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('user/'+data.user.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    passwordRecovery({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('password-recovery', data)
        .then(res => {
          if(res.data.data == 'success') {
            resolve(res)
          } else reject(res)
        })
        .catch(err => reject(err))
      })
    },
    changePassword({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('change-password', data)
        .then(res => {
          if(res.data.data == 'success') {
            resolve(res)
          } else reject(res)
        })
        .catch(err => reject(err))
      })
    },
  }
}
